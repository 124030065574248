<script>
import Vue from 'vue';
import { VTextField } from 'vuetify/lib';

const InputField = Vue.component('inputField', {
    name: 'InputField',
    extends: VTextField,
    props: {
        outlined: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: true
        },
        hideDetails: {
          type: Boolean,
          default: true
        }
    }
})

export default InputField
</script>
